<template>
  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/banner2.png" alt="">
    </div>
    <div class="homemy">
      <div class="homerecommendtop">
        <h3>
          关于我们
          <span class="heng"></span>
        </h3>
      </div>
      <div class="homemyText">
        <div class="homemytxt" v-for="(item,index) in str1.text" :key="index">
          <p>
            <span class="leftL"></span>
            <span>{{item.title}}</span>
          </p>
          <p style="font-size:14px">{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, toRefs } from "vue"
let str1 = reactive({
  text: [{
      title: "平台信息",
      text: "大健康电商平台是“永不落幕的健博会”重要的承载体，它利用互联网+ 的手段，为企业提供产品信息展示"
    },
    {
      title: "经营信息",
      text: "大健康电商平台主要展示药品、器械相关产品信息"
    }
  ]
})
</script>
<style scoped lang="less">
.app_cp {
  background: #ffffff;
}
.homebanner {
  img {
    width: 100%;
  }
}
.homemy {
  width: 1024px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 40px;
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }

  .homemyText {
    width: 900px;
    margin-left: 50px;
    .homemyImg {
      height: 321px;
      img {
        width: 100%;
      }
    }
    .homemytxt {
      p {
        display: flex;
        margin-bottom: 10px;
      }
      .leftL {
        display: block;
        width: 3px;
        height: 16px;
        background-color: #116d52;
        margin-right: 6px;
        margin-top: 3px;
      }
    }
  }
}
</style>